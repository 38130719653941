import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const tacs3TacsIcon: IconDefinition = {
  prefix: 'tacs3' as IconPrefix,
  iconName: 'tacs' as IconName,
  icon: [
    16,
    12.298767,
    [],
    '&#xe80d;',
    'M 7.9947919,0 C 6.4200267,0 5.0473433,1.9292922 4.2599638,3.3464014 5.471317,2.994567 6.7332954,2.818815 8.0052081,2.8285895 9.2670493,2.8188201 10.518487,2.9848238 11.729846,3.3366571 10.932385,1.9195489 9.5695408,0 7.9947919,0 Z m 3.7350541,3.3366571 c 0.958999,1.7591683 0.958999,3.8603918 0,5.6195611 C 13.698287,8.3698282 16,7.3339757 16,6.1514231 16,4.9688707 13.688194,3.9328188 11.729846,3.3366571 Z m -7.4698822,0 C 2.3016108,3.9328188 0,4.9688707 0,6.1514231 0,7.3339757 2.3016108,8.3698282 4.2599638,8.9562182 3.7855188,8.0961794 3.5432519,7.1287393 3.5432519,6.1514231 c 0,-0.9773155 0.2422669,-1.9449551 0.7167119,-2.814766 z m 0,5.6195611 c 0.7974748,1.4171158 2.1701581,3.3425488 3.7348281,3.3425488 1.5646562,0 2.9375931,-1.915688 3.7350541,-3.3328043 C 10.518487,9.3177932 9.2670493,9.4840261 8.0052081,9.4742567 6.7332954,9.4840957 5.471317,9.3080551 4.2599638,8.9562182 Z',
  ],
};

// @ts-expect-error custom icon
export const tacsIconProps = ['tacs3', 'tacs'] as IconProp;
